import React from 'react';
import Loadable from '@components/Loadable';
import { lazy } from 'react';
import Product from './Product';

const Profile = Loadable(lazy(() => import('./User/Profile/index')));
const Profile_ = Loadable(lazy(() => import('./User/ProfileRefactor/index')));
const ProfileUser = Loadable(
  lazy(() => import('./User/ProfileRefactor/components/contents/ProfileUser'))
);
const Wallet = Loadable(lazy(() => import('./User/Wallet')));
const AdminUserProduct = Loadable(lazy(() => import('../AdminPage/AdminUserProduct')));
const AuctionPage = Loadable(lazy(() => import('../AdminPage/Auction')));
const Products = Loadable(lazy(() => import('../AdminPage/Products')));
const ProductSearch = Loadable(lazy(() => import('./ProductSearchEngine/index')));
const CustomerDashboard = Loadable(lazy(() => import('./Dashboard/index')));

const ProfileUserChangePassword = Loadable(
  lazy(() =>
    import('./User/ProfileRefactor/components/contents/change-password/ProfileUserChangePasswrod')
  )
);
const ProfileUserAddress = Loadable(
  lazy(() => import('./User/ProfileRefactor/components/contents/address/ProfileUserAddress'))
);
const ProfileUserWallet = Loadable(
  lazy(() => import('./User/ProfileRefactor/components/contents/Wallet/ProfileUserWallet'))
);

const UserAuction = Loadable(
  lazy(() => import('./User/ProfileRefactor/components/contents/Auction/UserAuction'))
);

const ManageBids = Loadable(lazy(() => import('../AdminPage/ManageBids')));
const customerRouters = [
  { path: '/account/auction/:params', element: <AuctionPage /> },
  { path: '/account/products', element: <Products /> },
  { path: '/admin/bids', element: <ManageBids /> },
  { path: '/account/profile', element: <Profile /> },
  { path: '/account/profile-refactor', element: <Profile_ /> },
  { path: '/account/profile-view', element: <ProfileUser /> },
  { path: '/account/profile-change-password', element: <ProfileUserChangePassword /> },
  { path: '/account/address', element: <ProfileUserAddress /> },
  { path: '/account/user-wallet', element: <ProfileUserWallet /> },
  { path: '/account/user-auction', element: <UserAuction /> },
  { path: '/account/wallet', element: <Wallet /> },
  { path: '/customer/dashboard', element: <CustomerDashboard /> },
  { path: '/product/category/:categoryId', element: <Product /> },
  { path: '/auction', element: <Product /> },
  { path: '/auction/seller/:sellerId', element: <Product /> },
  { path: '/product/search/:search', element: <ProductSearch /> }
];

export default customerRouters;
