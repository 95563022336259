import axios from 'axios';
import authHeader from './AuthHeader';

import { get, isEmpty } from 'lodash';
import { URL_API } from '@configs/';
import { loadFromLocalStorageObjectFromBase64, saveToLocalStorage } from '@databases/localStorage';
import { contantAuthentication } from '@contants/';
import { showError } from '@components/notification';
import { useNavigate } from 'react-router-dom';

const rootApi = axios.create({
  baseURL: URL_API,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
  timeout: 30000
});

rootApi.interceptors.request.use(function (config) {
  let user = loadFromLocalStorageObjectFromBase64(contantAuthentication.DATA_AUTH);
  if (!isEmpty(user)) {
    user = JSON.parse(user);
  }
  config.headers.Authorization = user?.access_token ? `Bearer ${user?.access_token}` : '';
  return config;
});

rootApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      showError('Phiên đăng nhập hết hạn, vui lòng đăng nhập lại!');
      setTimeout(() => (window.location.href = '/account/signin'), 2000);
    } else {
      showError(error.response?.data?.status?.message || 'Vui lòng liên hệ admin để được hỗ trợ');
    }
    return Promise.reject(error?.response?.data);
  }
);

export default rootApi;
