import React from 'react';
import { Button, Divider, Modal, Typography } from 'antd';
import trashSvg from '@assets/images/user/trash.svg'; // Import your SVG image
import './DeleteModal.css';
import { useMutation } from 'react-query';
import { showError, showSuccess } from '@components/notification';
import rootApi from '@utils/baseAPI/WrapperApi';
import { useNavigate } from 'react-router-dom';
const { Text } = Typography;

const BlockModal = ({ visible, setVisible, userInfo, refetch }) => {
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useMutation('block-operator', () => {
    return rootApi.post(`/admin/users/locked-bidding/${userInfo?.id}`);
  });

  const handleConfirm = () => {
    mutateAsync()
      .then(() => {
        setVisible(false);
        !refetch && navigate(`/admin/user`);
        showSuccess({ message: 'Khoá người dùng thành công' });
        refetch && refetch();
      })
      .catch((error) => {});
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" style={{ height: '36px' }} onClick={() => setVisible(false)}>
          Hủy
        </Button>,
        <Button
          key="submit"
          style={{ backgroundColor: '#00904A', color: '#FFFF', height: '36px' }}
          onClick={handleConfirm}>
          Xác nhận
        </Button>
      ]}
      className="delete-modal">
      <div className="delete-icon-container">
        <img src={trashSvg} alt="Delete Icon" className="delete-icon" />
        <Text style={{ marginTop: '8px', display: 'block', fontSize: '18px', fontWeight: 600 }}>
          Khoá người dùng
        </Text>
        <Text
          style={{
            marginTop: '8px',
            display: 'block',
            color: '#626D7C',
            fontSize: '14px',
            fontWeight: 400
          }}>
          Bạn có chắc chắn muốn khoá người dùng <strong>{userInfo?.username}</strong>?
        </Text>
      </div>
    </Modal>
  );
};

export default BlockModal;
