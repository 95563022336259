import React, { useEffect } from 'react';
import { ProductCard } from '@page/AdminPage/Products/components/ProductCard';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

export const MainContent = ({ products }) => {
  const navigate = useNavigate();
  const goToDetailPage = (product) => {
    navigate(
      `/account/auction?dataSearch=https://page.auctions.yahoo.co.jp/jp/auction/${product.code}`,
      { state: { fromSearch: true, payload: true } }
    );
  };

  return (
    <div>
      <Row gutter={12} style={{ maxWidth: 1444, margin: 'auto' }}>
        {products.length > 0 &&
          products.map((product) => (
            <Col onClick={() => goToDetailPage(product)} key={product.code} span={12} sm={8} lg={6}>
              <ProductCard product={product} />
            </Col>
          ))}
      </Row>
    </div>
  );
};
