import { ReactComponent as DashboardSvg } from '@assets/images/customer/svg/dashboard.svg';
import { ReactComponent as AuctionSvg } from '@assets/images/customer/svg/auction.svg';
import { ReactComponent as FavoriteSvg } from '@assets/images/customer/svg/favorite.svg';
import { ReactComponent as HistorySvg } from '@assets/images/customer/svg/history.svg';
import { ReactComponent as LocationSvg } from '@assets/images/customer/svg/location.svg';
import { ReactComponent as OrderSvg } from '@assets/images/customer/svg/orders.svg';
import { ReactComponent as PersonalSvg } from '@assets/images/customer/svg/personal.svg';
import { ReactComponent as ProtectSvg } from '@assets/images/customer/svg/protect.svg';
import { ReactComponent as WalletSvg } from '@assets/images/customer/svg/wallet.svg';
import { ReactComponent as WarningSvg } from '@assets/images/customer/svg/warning.svg';
import { ReactComponent as VectorSvg } from '@assets/images/user/vector.svg';
import { ReactComponent as VectorRight } from '@assets/images/customer/svg/vector-right.svg';
import { ReactComponent as JPFlagSvg } from '@assets/images/fees/flag_jp.svg';
import { ReactComponent as OnOffSvg } from '@assets/images/adminPage/onoff.svg';
import { ReactComponent as BagSvg } from '@assets/images/customer/bag.svg';
import { ReactComponent as CheckSvg } from '@assets/images/customer/check.svg';
import { ReactComponent as EditSvg } from '@assets/images/customer/edit.svg';
import { ReactComponent as SyncSvg } from '@assets/images/customer/svg/sync.svg';
import { ReactComponent as TranslateSvg } from '@assets/images/customer/svg/translate.svg';
import { ReactComponent as NotFoundSvg } from '@assets/images/customer/svg/notFound.svg';
import { ReactComponent as BlockSvg } from '@assets/images/customer/svg/block.svg';

export const Svgs = {
  DashboardSvg,
  AuctionSvg,
  FavoriteSvg,
  HistorySvg,
  LocationSvg,
  OrderSvg,
  PersonalSvg,
  ProtectSvg,
  WalletSvg,
  WarningSvg,
  VectorSvg,
  VectorRight,
  JPFlagSvg,
  OnOffSvg,
  BagSvg,
  CheckSvg,
  EditSvg,
  SyncSvg,
  TranslateSvg,
  NotFoundSvg,
  BlockSvg
};
