import React, { useState } from 'react';
import { Row, Col, Button, Typography, Modal, Card } from 'antd';
import { useParams } from 'react-router-dom';
import avatarImage from '@assets/images/user/avatar.png';
import { formatDateToString } from '@utils/utils';
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  StopOutlined
} from '@ant-design/icons';
import DeleteConfirmationModal from '../Dialogs/DialogsDelete';
import DialogsChangeRole from '../Dialogs/DialogsChangeRole';
import { useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import { Box } from '@mui/material';
import Loading from '@components/Loading';
import moment from 'moment';
import { Svgs } from '@components/IconSvg';
import BlockModal from '../Dialogs/DialogsBlock';

const { Text } = Typography;

export const Detail = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery(['admin-detail-user'], () =>
    rootApi.get(`/admin/users/${id}`)
  );
  const userInfo = data?.data.data || {};

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [blockedModalVisible, setBlockedModalVisible] = useState(false);

  const handleEdit = () => {
    setEditModalVisible(true);
  };

  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };
  const handleBlocked = () => {
    setBlockedModalVisible(true);
  };
  return (
    <>
      <div className="detail-customer-page">
        <Row justify="space-between" align="middle">
          <Col>
            <Typography.Title style={{ marginTop: 20, fontSize: '18px', fontWeight: '500' }}>
              Chi tiết người dùng
            </Typography.Title>
          </Col>
          <Col></Col>
        </Row>

        <Box width="100%">
          <Card>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <div className="banner">
                  <div className="avatar">
                    <img src={avatarImage} alt=""></img>
                  </div>
                  <div className="info">
                    <div className="name">{userInfo?.full_name ?? 'Không xác định'} </div>
                    <div className="email">{userInfo?.email}</div>
                  </div>
                  <div className="group-button">
                    <Button
                      onClick={() => setDeleteModalVisible(true)}
                      icon={<DeleteOutlined />}
                      style={{
                        backgroundColor: '#E8EBEF',
                        color: '#192434',
                        height: '36px',
                        marginRight: '8px'
                      }}>
                      Xóa người dùng
                    </Button>
                    {/* <Button
                      onClick={() => setBlockedModalVisible(true)}
                      style={{
                        height: '36px',
                        backgroundColor: '#E8EBEF',
                        color: '#192434',
                        marginRight: '8px'
                      }}
                      icon={<StopOutlined />}>
                      Khoá người dùng
                    </Button> */}
                    <Button
                      onClick={() => setEditModalVisible(true)}
                      style={{
                        height: '36px',
                        backgroundColor: '#00904A',
                        color: '#FFFFFF'
                      }}
                      icon={<EditOutlined />}>
                      Chỉnh sửa
                    </Button>
                  </div>
                </div>
                <div className="detail-customer-overview">
                  <div>
                    <div style={{ fontWeight: 500, fontSize: 18, marginBottom: 15 }}>
                      Thông tin người dùng
                    </div>
                    <Row style={{ paddingBottom: 10 }}>
                      <Col span={4}>
                        <div className="label">Họ và tên</div>
                      </Col>
                      <Col span={18}>
                        <div className="value">{userInfo?.full_name ?? '-'}</div>
                      </Col>
                    </Row>
                    <Row style={{ paddingBottom: 10 }}>
                      <Col span={4}>
                        <div className="label">Số điện thoại</div>
                      </Col>
                      <Col span={18}>
                        <div className="value">{userInfo?.phone_number ?? '-'}</div>
                      </Col>
                    </Row>
                    <Row style={{ paddingBottom: 10 }}>
                      <Col span={4}>
                        <div className="label">Email</div>
                      </Col>
                      <Col span={18}>
                        <div className="value">{userInfo?.email ?? '-'}</div>
                      </Col>
                    </Row>
                    <Row style={{ paddingBottom: 10 }}>
                      <Col span={4}>
                        <div className="label">Nhóm quyền</div>
                      </Col>
                      <Col span={18}>
                        <div className="value">{userInfo?.roles?.toString() ?? '-'}</div>
                      </Col>
                    </Row>
                    <Row style={{ paddingBottom: 10 }}>
                      <Col span={4}>
                        <div className="label">Thời gian tạo</div>
                      </Col>
                      <Col span={18}>
                        <div className="value">
                          {userInfo?.created_date
                            ? moment(userInfo?.created_date).format('DD-MM-YYYY HH:mm:ss')
                            : '-'}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ paddingBottom: 10 }}>
                      <Col span={4}>
                        <div className="label">Trạng thái</div>
                      </Col>
                      <Col span={18}>
                        <div className="value">
                          {userInfo?.is_active === true ? 'Đang hoạt động' : 'Dừng hoạt động'}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            )}
          </Card>
        </Box>
        <DeleteConfirmationModal
          visible={deleteModalVisible}
          setVisible={setDeleteModalVisible}
          userInfo={userInfo}
        />
        <DialogsChangeRole
          open={editModalVisible}
          setOpen={setEditModalVisible}
          userInfo={userInfo}
        />
        {/* <BlockModal
          visible={blockedModalVisible}
          setVisible={setBlockedModalVisible}
          userInfo={userInfo}
          refetch={refetch}
        /> */}
      </div>
    </>
  );
};
